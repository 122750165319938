<template>
  <div class="flexCol" v-loading="pageloading">
    <el-card>
      <div class="titlefont">直播推广：</div>
      <div class="secfont" style="margin-top:10px">1.添加并邀请视频号成为小程序推广员，视频号接受邀请后，可添加「商品橱窗」已审核通过的商品至自己的视频号或直播购物袋中，实现带货</div>
      <div class="secfont">2.直播推广页面可查看已成为小程序推广员的视频号信息，以及视频号的带货明细</div>
      <div class="secfont">3.一个小程序最多添加100个推广员。
        <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnKeBnupduzfeevkx5KTCCSe" type="primary" :underline="false" target="_blank">
        查看推广员添加方法</el-link>
      </div>
      <div class="secfont">4.一个视频号只能关联1个小程序，视频号如果已经成为其他小程序的推广员，可进入带货中心小程序自行解绑。
        <el-link href="https://jusnn6k8al.feishu.cn/docx/doxcnjrWs4OOVDB82AV7Ca16Ngf" type="primary" :underline="false" target="_blank">
        查看解绑方法</el-link>
      </div>
    </el-card>
    <div style="margin-top:10px;">
      <cut-in v-if="Access<4" :font="'自定义版交易组件'" style="position: absolute; top: 170px; bottom: 0px;right:0px;left:0px"></cut-in>
      <el-card v-else>
        <div class="flexRow" style="justify-content: space-between;">
          <div class="flexRow" style="flex-wrap:wrap">
            <div class="flexRow" style="margin-right:10px">
              <div class="keyFont">关键字：</div>
              <el-input v-model="keywords" style="width:200px" placeholder="视频号昵称"></el-input>
            </div>
            <div class="flexRow" style="margin-right:10px">
              <div class="keyFont flexRow">带货时间
                <el-popover placement="top" trigger="hover" content="订单支付成功时间">
                  <i class="el-icon-question" style="color:#C0C4CC;margin-left:3px" slot="reference"></i>
                </el-popover>
                ：</div>
              <el-date-picker
                v-model="startTime"
                style="width:200px"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                placeholder="起始时间">
              </el-date-picker>
              <span style="margin:0px 10px">~</span>
              <el-date-picker
                v-model="endTime"
                style="width:200px"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                placeholder="结束时间">
              </el-date-picker>
            </div>
            <el-button type="primary" @click="query(1)">查询</el-button>
            <buttonPermissions :datas="'promoteExport'" style="margin-left:10px">
              <el-button @click="exportlist(1)">导出</el-button>
            </buttonPermissions>
          </div>
          <div class="flexCol" style="align-items:flex-end">
            <div class="flexRow">
              <i class="el-icon-warning"></i>
              <el-link href="https://jusnn6k8al.feishu.cn/docx/doxcnRL08Q7kGrDukGTVGkhTfJH" :underline="false" target="_blank">
                数据统计说明</el-link>
            </div>
            <div class="secfont" style="color:#909399">(数据更新至昨日，每日凌晨4点更新)</div>
          </div>
        </div>
        <div style="margin-top:10px">
          <el-table :data="tablelist" v-loading="tableloading">
            <el-table-column prop="" label="视频号昵称" min-width="250px">
              <template slot-scope="scope">
                <div class="flexRow">
                  {{scope.row.FinderNickname}}
                  <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/31cce753de88fa4c4dd1cb6dd472c68.png"
                   style="width:80px;margin-left:5px" v-if="scope.row.IsUnBind" alt="">
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="OrderCount" label="带货订单数"></el-table-column>
            <el-table-column prop="" label="带货总额(元)">
              <template slot-scope="scope">
                ￥{{scope.row.OrderProductMoney.toFixed(2)}}
              </template>
            </el-table-column>
            <el-table-column prop="" label="订单运费(元)">
              <template slot-scope="scope">
                ￥{{scope.row.OrderFreightMoney.toFixed(2)}}
              </template>
            </el-table-column>
            <el-table-column prop="" label="退款金额(元)">
              <template slot-scope="scope">
                ￥{{scope.row.RefundProductMoney.toFixed(2)}}
              </template>
            </el-table-column>
            <el-table-column prop="" label="退回运费(元)">
              <template slot-scope="scope">
                ￥{{scope.row.RefundFreightMoney.toFixed(2)}}
              </template>
            </el-table-column>
            <el-table-column prop="" label="带货利润(元)">
              <template slot-scope="scope">
                ￥{{scope.row.Profit.toFixed(2)}}
              </template>
            </el-table-column>
            <el-table-column prop="" label="操作">
              <template slot-scope="scope">
                <buttonPermissions :datas="'promotegoodsdetail'">
                  <el-button type="text" @click="lookdetail(scope.row)">带货明细</el-button>
                </buttonPermissions>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top:10px;text-align:right" v-if="total">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="pagesize"
              layout="total, prev, pager, next, jumper"
              :total="total">
            </el-pagination>
          </div>
        </div>

        <el-dialog :visible.sync="detailShow" :title="'推广员带货明细-'+rowmsg.FinderNickname" width="1000px" @close="closedialog">
          <div class="flexRow">
            <div class="flexRow" style="margin-right:10px">
              <div class="keyFont">关键字：</div>
              <el-input v-model="keywords2" style="width:200px" placeholder="订单编号"></el-input>
            </div>
            <div class="flexRow" style="margin-right:10px">
              <div class="keyFont flexRow">带货时间
                <el-popover placement="top" trigger="hover" content="订单支付成功时间">
                  <i class="el-icon-question" style="color:#C0C4CC;margin-left:3px" slot="reference"></i>
                </el-popover>
                ：</div>
              <el-date-picker
                v-model="startTime2"
                style="width:200px"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                placeholder="起始时间">
              </el-date-picker>
              <span style="margin:0px 10px">~</span>
              <el-date-picker
                v-model="endTime2"
                style="width:200px"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm:ss"
                placeholder="结束时间">
              </el-date-picker>
            </div>
            <el-button type="primary" @click="query(2)">查询</el-button>
            <buttonPermissions :datas="'promoteExport2'" style="margin-left:10px">
              <el-button @click="exportlist(2)">导出</el-button>
            </buttonPermissions>
          </div>
          <div style="margin-top:10px">
            <el-table :data="tablelist2" v-loading="tableloading2" max-height="500px">
              <el-table-column prop="" label="订单编号" min-width="250">
                <template slot-scope="scope">
                  <div class="parmarytext" @click="jumpto(scope.row)">{{scope.row.OrderNo}}</div>
                </template>
              </el-table-column>
              <el-table-column prop="OrderStateValue" label="订单状态"></el-table-column>
              <el-table-column prop="" label="订单实付(元)" width="150">
                <template slot="header">
                  <div class="flexRow">
                    <span>订单实付(元)</span>
                    <el-popover placement="top" trigger="hover">
                      <div style="max-width:300px">
                        不包含运费，不剔除已退款金额
                      </div>
                      <i class="el-icon-warning" style="color:#C0C4CC;margin-left:3px" slot="reference"></i>
                    </el-popover>
                  </div>
                </template>
                <template slot-scope="scope">
                  ￥{{scope.row.OrderProductMoney.toFixed(2)}}
                </template>
              </el-table-column>
              <el-table-column prop="" label="订单运费(元)" width="150">
                <template slot="header">
                  <div class="flexRow">
                    <span>订单运费(元)</span>
                    <el-popover placement="top" trigger="hover">
                      <div style="max-width:300px">
                        客户下单时支付的订单运费或同城配送费
                      </div>
                      <i class="el-icon-warning" style="color:#C0C4CC;margin-left:3px" slot="reference"></i>
                    </el-popover>
                  </div>
                </template>
                <template slot-scope="scope">
                  ￥{{scope.row.FreightMoney.toFixed(2)}}
                </template>
              </el-table-column>
              <el-table-column prop="" label="退款金额(元)" width="150">
                <template slot="header">
                  <div class="flexRow">
                    <span>退款金额(元)</span>
                    <el-popover placement="top" trigger="hover">
                      <div style="max-width:300px">
                        订单已退款成功金额，不包含退回运费
                      </div>
                      <i class="el-icon-warning" style="color:#C0C4CC;margin-left:3px" slot="reference"></i>
                    </el-popover>
                  </div>
                </template>
                <template slot-scope="scope">
                  ￥{{scope.row.RefundProductMoney.toFixed(2)}}
                </template>
              </el-table-column>
              <el-table-column prop="" label="退回运费(元)" width="150">
                <template slot="header">
                  <div class="flexRow">
                    <span>退回运费(元)</span>
                    <el-popover placement="top" trigger="hover">
                      <div style="max-width:300px">
                        退款时同时退回给客户的运费或同城配送费
                      </div>
                      <i class="el-icon-warning" style="color:#C0C4CC;margin-left:3px" slot="reference"></i>
                    </el-popover>
                  </div>
                </template>
                <template slot-scope="scope">
                  ￥{{scope.row.RefundFreightMoney.toFixed(2)}}
                </template>
              </el-table-column>
              <el-table-column prop="" label="订单利润(元)" width="150">
                <template slot="header">
                  <div class="flexRow">
                    <span>订单利润(元)</span>
                    <el-popover placement="top" trigger="hover">
                      <div style="max-width:300px">
                        订单利润=订单实付-退款金额-推手收益-员工提成
                      </div>
                      <i class="el-icon-warning" style="color:#C0C4CC;margin-left:3px" slot="reference"></i>
                    </el-popover>
                  </div>
                </template>
                <template slot-scope="scope">
                  ￥{{scope.row.Profit.toFixed(2)}}
                </template>
              </el-table-column>
              <el-table-column prop="PayTime" label="支付时间" width="150">
                <template slot-scope="scope">
                  {{scope.row.OrderState==1?'未支付':scope.row.PayTime}}
                </template>
              </el-table-column>
              <el-table-column prop="ReceiveTime" label="完成时间" width="150">
                <template slot-scope="scope">
                  {{scope.row.ReceiveTime=='2000-01-01 00:00:00'?'--':scope.row.ReceiveTime}}
                </template>
              </el-table-column>
            </el-table>
            <div style="margin-top:10px;text-align:right" v-if="total2">
              <el-pagination
                @size-change="handleSizeChange2"
                @current-change="handleCurrentChange2"
                :current-page="currentPage2"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="pagesize2"
                layout="total, prev, pager, next, jumper"
                :total="total2">
              </el-pagination>
            </div>
          </div>
        </el-dialog>
      </el-card>
    </div>
  </div>
</template>

<script>
import config from "@/config"
import cutIn from "./components/cutIn"
import buttonPermissions from '@/components/buttonPermissions';
import{
  videolivevideoliveapplyinfo,
}from "@/api/sv1.0.0"
import{
  videoLivePromotervideoLivePromoterStatistics,
  videoLivePromotervideoLivePromoterDetail
}from "@/api/sv3.0.0"
export default {
  components: {
    cutIn,
    buttonPermissions
  },
  data () {
    return {
      exporturl:config.EXPORT_URL,
      Access:0,
      pageloading:false,
      keywords:'',
      startTime:'',
      endTime:'',
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
      rowmsg:{},
      detailShow:false,
      keywords2:'',
      startTime2:'',
      endTime2:'',
      tablelist2:[],
      tableloading2:false,
      currentPage2:1,
      pagesize2:10,
      total2:0,
    }
  },
  created () {
    this.getvideolivevideoliveapplyinfo()
  },
  methods: {
    closedialog(){
      this.detailShow=false
      this.keywords2=''
      this.startTime2=''
      this.endTime2=''
      this.currentPage2=1
    },
    jumpto(row){
      this.$router.push({
        path:'/order/orderDetailpage',
        query:{
          Id:row.OrderNo
        }
      })
    },
    async getvideolivevideoliveapplyinfo(){
      this.pageloading = true
      let result = await videolivevideoliveapplyinfo()
      if(result.IsSuccess){
        // console.log(result.Result)
        
        this.Access = result.Result.State
        // this.Access = 0
        // console.log(this.Access)
        if(this.Access>=4){
          this.gettablelist()
        }
      }
      this.pageloading = false
    },
    query(num){
      if(num == 1){
        this.currentPage = 1
        this.gettablelist()
      }else{
        this.currentPage2 = 1
        this.gettablelist2()
      }
    },
    exportlist(num){
      let url = ''
      if(num==1){
        url = this.exporturl+'/pc/videoLivePromoter/videoLivePromoterDataExport'+
              '?Keywords='+(this.keywords||'')+
              '&StartTime='+(this.startTime?this.startTime:'')+
              '&EndTime='+(this.endTime?this.endTime:'')
      }else{
        url = this.exporturl+'/pc/videoLivePromoter/videoLivePromoterDetailExport'+
              '?VideoLivePromoterId='+(this.rowmsg.VideoLivePromoterId||'')+
              '&Keywords='+(this.keywords2||'')+
              '&StartTime='+(this.startTime2?this.startTime2:'')+
              '&EndTime='+(this.endTime2?this.endTime2:'')
      }
      // console.log(url)
      window.open(url)
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let data = {
          Keywords:this.keywords,
          StartTime:this.startTime,
          EndTime:this.endTime,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize,
        }
        let res = await videoLivePromotervideoLivePromoterStatistics(data)
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    },
    lookdetail(row){
      this.rowmsg = row
      this.detailShow = true
      this.gettablelist2()
    },
    handleSizeChange2(e){
      this.currentPage2 = 1
      this.pagesize2 = e
      this.gettablelist2()
    },
    handleCurrentChange2(e){
      this.currentPage2 = e
      this.gettablelist2()
    },
    async gettablelist2(){
      try{
        this.tableloading2 = true
        let data = {
          VideoLivePromoterId:this.rowmsg.VideoLivePromoterId,
          Keywords:this.keywords2,
          StartTime:this.startTime2,
          EndTime:this.endTime2,
          Skip:(this.currentPage2-1)*this.pagesize2,
          Take:this.pagesize2,
        }
        let res = await videoLivePromotervideoLivePromoterDetail(data)
        if(res.IsSuccess){
          this.tablelist2 = res.Result.Results
          this.total2 = res.Result.Total
        }
      }finally{
        this.tableloading2 = false
      }
    },
  }
}
</script>

<style lang="less" scoped>
  .flexCol{
    display: flex;
    flex-direction: column;
  }
  .titlefont{
    font-size: 16px;
    color: #303133;
    line-height: 1.5;
  }
  .secfont{
    font-size:14px;
    color: #606266;
    line-height: 1.5;
  }
  .flexRow{
    display: flex;
    align-items: center;
    
  }
  .keyFont{
    font-size: 14px;
    color: #606266;
    font-weight: bold;
    line-height: 1.5;
    white-space: nowrap;
  }
  .parmarytext{
    color: #409eff;
    cursor: pointer;
    white-space: nowrap;
  }
</style>